import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import { useSpeechSynthesis } from "react-speech-kit"
import Layout from "../components/layout"
import Seo from "../components/seo"
import App from "../components/expressions/App1"

const Expressions = () => {
  //   const [value, setValue] = useState("")
  //   const { speak } = useSpeechSynthesis()
  return (
    <Layout>
      <Seo title="Expressions" />

      <section className="py-6 dark:bg-coolGray-800 dark:text-coolGray-50 flex ">
        <div className="container mx-auto flex flex-col items-center justify-center p-4  md:p-10 md:px-24 xl:px-48 ">
          <aside class="  lg:p-4">
            <div class="  text-center">
              {/* <p class="mt-2 text-5xl text-[#33c3b0] sm:text-4xl">
                Type what you want said
              </p> */}
              <nav
            aria-label="breadcrumb"
            className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
          >
            <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
              <li className="flex items-center">
                <a
                  rel="noopener noreferrer"
                  href="/"
                  title="Back to homepage"
                  className="flex items-center hover:underline"
                >
                  Home
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/know"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  About
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/started"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  Features
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/contact"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  Contact
                </a>
              </li>
            </ol>

            <footer className="animate-pulse text-center text-sm">
              © {new Date().getFullYear()}, An Idea by
              {` `}
              <a
                className="text-center text-sm"
                href="https://www.chakama.co.zw"
              >
                George Chakama
              </a>
            </footer>
          </nav>
              <App />
            </div>
          </aside>

          <nav
            aria-label="breadcrumb"
            className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
          >
            <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
              <li className="flex items-center">
                <a
                  rel="noopener noreferrer"
                  href="/"
                  title="Back to homepage"
                  className="flex items-center hover:underline"
                >
                  Home
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/know"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  About
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/started"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  Features
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/contact"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  Contact
                </a>
              </li>
            </ol>

            <footer className="animate-pulse text-center text-sm">
              © {new Date().getFullYear()}, An Idea by
              {` `}
              <a
                className="text-center text-sm"
                href="https://www.chakama.co.zw"
              >
                George Chakama
              </a>
            </footer>
          </nav>
        </div>
      </section>

      {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        alt="gatsby astronaut"
      />
    </div>
    <p>
      <Link to="/page-2/">Go to page 2</Link>
    </p>
    <p>
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
    </Layout>
  )
}
export default Expressions
