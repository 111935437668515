import React, { useState } from "react"
import PhrasesData from "./data/phrases.tsx"
import "./App.css"
import { useSpeechSynthesis } from "react-speech-kit"

function App() {
  // the value of the search field
  const [phrase, setPhrase] = useState("")
  const { speak } = useSpeechSynthesis()

  // the search result
  const [foundPhrases, setFoundPhrases] = useState(PhrasesData)

  const filter = e => {
    const keyword = e.target.value

    if (keyword !== "") {
      const results = PhrasesData.filter(phrases => {
        return phrases.phrase.toLowerCase().startsWith(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundPhrases(results)
    } else {
      setFoundPhrases(PhrasesData)
      // If the text field is empty, show all Phrases
    }

    setPhrase(keyword)
  }

  return (
    <div className="container">
      <div className="title">
        <h1 className="text-5xl text-[#33c3b0] mb-4  text-center">
          Search Expressions
        </h1>
        <p className="text-gray-400 pb-4 text-xl">
          This search input picks words in phrases frequently used that are
          stored in our database.
        </p>
        <span className=" text-center my-4 text-emerald-300">
          This Page is Still Under Development.
        </span>
      </div>
      <input
        type="search"
        value={phrase}
        onChange={filter}
        className="w-full p-3 mt-4 text-gray border-2 border-gray-400 rounded-lg focus:outline-none focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
        placeholder="Search Phrase"
      />

      <div className="phrase-list">
        {foundPhrases && foundPhrases.length > 0 ? (
          foundPhrases.map(phrase => (
            <li key={phrase.id} className="flex text-left  mt-4">
              <div className="grow  overflow-visible  p-2 my-2 text- w-3/6 flex justify-start  content-center">
                <span className="bold mr-2">{phrase.id}</span>
                <span className="text-gray-500">{phrase.phrase}</span>
              </div>
              <button>
                <span className="mr-2">{phrase.category} </span>
              </button>
              <button
                class="hover:animate-spin z-10 block p-4 text-green-700 transition-all bg-green-100 border-2 border-white rounded-full active:bg-green-50 hover:scale-110 focus:outline-none focus:ring"
                type="button"
                onClick={() => {
                  // setValue(phrase.phrase)
                  speak({ text: phrase.phrase })
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                  />
                </svg>
              </button>
            </li>
          ))
        ) : (
          <h1 className="m-6">No results found for {phrase} !😅</h1>
        )}
      </div>
    </div>
  )
}

export default App
