const PhrasesData = [
    {
      id: 1,
      phrase: "Be careful.",
      category: "🥧"
    },
    {
      id: 2,
      phrase: "Be careful driving.",
      category: "😰"
    },
    {
      id: 3,
      phrase: "Can you translate this for me?",
      category: "😩"
    },
    {
      id: 4,
      phrase: "Harare is very different from Bulaway.",
      category: "😂"
    },
    {
      id: 5,
      phrase: "Don't worry.",
      category: "😤"
    },
    {
      id: 6,
      phrase: "Everyone knows it.",
      category: "🥂"
    },
    {
      id: 7,
      phrase: "Everything is ready.",
      category: "🔥"
    },
    {
      id: 8,
      phrase: "Excellent.",
      category: "💯"
    },
    {
      id: 9,
      phrase: "From time to time.",
      category: "😅"
    },
    {
      id: 10,
      phrase: "Good idea.",
      category: "🥧"
    },
    {
      id: 11,
      phrase: "He likes it very much.",
      category: "😰"
    },
    {
      id: 12,
      phrase: "Help!",
      category: "😩"
    },
    {
      id: 13,
      phrase: "He's coming soon.",
      category: "😂"
    },
    {
      id: 14,
      phrase: "He's right.",
      category: "😤"
    },
    {
      id: 15,
      phrase: "He's very annoying.",
      category: "🥂"
    },
    {
      id: 16,
      phrase: "He's very famous.",
      category: "🔥"
    },
    {
      id: 17,
      phrase: "How are you?",
      category: "💯"
    },
    {
      id: 18,
      phrase: "How's work going?",
      category: "😅"
    },
    {
      id: 19,
      phrase: "Hurry!",
      category: "🥧"
    },
    {
      id: 20,
      phrase: "I ate already.",
      category: "😰"
    },
    {
      id: 21,
      phrase: "I can't hear you.",
      category: "😩"
    },
    {
      id: 22,
      phrase: "I'd like to go for a walk.",
      category: "😂"
    },
    {
      id: 23,
      phrase: "I don't know how to use it.",
      category: "😤"
    },
    {
      id: 24,
      phrase: "I don't like him.",
      category: "🥂"
    },
    {
      id: 25,
      phrase: "I don't like it.",
      category: "🔥"
    },
    {
      id: 26,
      phrase: "I don't speak very well.",
      category: "💯"
    },
    {
      id: 27,
      phrase: "I don't understand.",
      category: "😅"
    },
    {
      id: 28,
      phrase: "I don't want it.",
      category: "🥧"
    },
    {
      id: 29,
      phrase: "I don't want that.",
      category: "😰"
    },
    {
      id: 30,
      phrase: "I don't want to bother you.",
      category: "😩"
    },
    {
      id: 31,
      phrase: "I feel good.",
      category: "😂"
    },
    {
      id: 32,
      phrase: "If you need my help, please let me know.",
      category: "😤"
    },
    {
      id: 33,
      phrase: "I get off of work at 6.",
      category: "🥂"
    },
    {
      id: 34,
      phrase: "I have a headache.",
      category: "🔥"
    },
    {
      id: 35,
      phrase: "I hope you and your wife have a nice trip.",
      category: "💯"
    },
    {
      id: 36,
      phrase: "I know.",
      category: "😅"
    },
    {
      id: 37,
      phrase: "I like her.",
      category: "🥧"
    },
    {
      id: 38,
      phrase: "I'll call you when I leave.",
      category: "😰"
    },
    {
      id: 39,
      phrase: "I'll come back later.",
      category: "😩"
    },
    {
      id: 40,
      phrase: "I'll pay.",
      category: "😂"
    },
    {
      id: 41,
      phrase: "I'll take it.",
      category: "😤"
    },
    {
      id: 42,
      phrase: "I'll take you to the bus stop.",
      category: "🥂"
    },
    {
      id: 43,
      phrase: "I lost my watch.",
      category: "🔥"
    },
    {
      id: 44,
      phrase: "I love you.",
      category: "💯"
    },
    {
      id: 45,
      phrase: "I'm an American.",
      category: "😅"
    },
    {
      id: 46,
      phrase: "I'm cleaning my room.",
      category: "🥧"
    },
    {
      id: 47,
      phrase: "I'm cold.",
      category: "😰"
    },
    {
      id: 48,
      phrase: "I'm coming to pick you up.",
      category: "😩"
    },
    {
      id: 49,
      phrase: "I'm going to leave.",
      category: "😂"
    },
    {
      id: 50,
      phrase: "I'm good, and you?",
      category: "😤"
    },
    {
      id: 51,
      phrase: "I'm happy.",
      category: "🥂"
    },
    {
      id: 52,
      phrase: "I'm hungry.",
      category: "🔥"
    },
    {
      id: 53,
      phrase: "I'm married.",
      category: "💯"
    },
    {
      id: 54,
      phrase: "I'm not busy.",
      category: "😅"
    },
    {
      id: 55,
      phrase: "I'm not married.",
      category: "🥧"
    },
    {
      id: 56,
      phrase: "I'm not ready yet.",
      category: "😰"
    },
    {
      id: 57,
      phrase: "I'm not sure.",
      category: "😩"
    },
    {
      id: 58,
      phrase: "I'm sorry, we're sold out.",
      category: "😂"
    },
    {
      id: 59,
      phrase: "I'm thirsty.",
      category: "😤"
    },
    {
      id: 60,
      phrase: "I'm very busy. I don't have time now.",
      category: "🥂"
    },
    {
      id: 61,
      phrase: "I need to change clothes.",
      category: "🔥"
    },
    {
      id: 62,
      phrase: "I need to go home.",
      category: "💯"
    },
    {
      id: 63,
      phrase: "I only want a snack.",
      category: "😅"
    },
    {
      id: 64,
      phrase: "Is Mr. Smith an American?",
      category: "🥧"
    },
    {
      id: 65,
      phrase: "Is that enough?",
      category: "😰"
    },
    {
      id: 66,
      phrase: "I think it's very good.",
      category: "😩"
    },
    {
      id: 67,
      phrase: "I think it tastes good.",
      category: "😂"
    },
    {
      id: 68,
      phrase: "I thought the clothes were cheaper.",
      category: "😤"
    },
    {
      id: 69,
      phrase: "It's longer than 2 miles.",
      category: "🥂"
    },
    {
      id: 70,
      phrase: "I've been here for two days.",
      category: "🔥"
    },
    {
      id: 71,
      phrase: "I've heard Texas is a beautiful place.",
      category: "💯"
    },
    {
      id: 72,
      phrase: "I've never seen that before.",
      category: "😅"
    },
    {
      id: 73,
      phrase: "I was about to leave the restaurant when my friends arrived.",
      category: "🥧"
    },
    {
      id: 74,
      phrase: "Just a little.",
      category: "😰"
    },
    {
      id: 75,
      phrase: "Just a moment.",
      category: "😩"
    },
    {
      id: 76,
      phrase: "Let me check.",
      category: "😂"
    },
    {
      id: 77,
      phrase: "Let me think about it.",
      category: "😤"
    },
    {
      id: 78,
      phrase: "Let's go have a look.",
      category: "🥂"
    },
    {
      id: 79,
      phrase: "Let's practice English.",
      category: "🔥"
    },
    {
      id: 80,
      phrase: "May I speak to Mrs. Smith please?",
      category: "💯"
    },
    {
      id: 81,
      phrase: "More than that.",
      category: "😅"
    },
    {
      id: 82,
      phrase: "Never mind.",
      category: "🥧"
    },
    {
      id: 83,
      phrase: "Next time.",
      category: "😰"
    },
    {
      id: 84,
      phrase: "No.",
      category: "😩"
    },
    {
      id: 85,
      phrase: "Nonsense.",
      category: "😂"
    },
    {
      id: 86,
      phrase: "No, thank you.",
      category: "😤"
    },
    {
      id: 87,
      phrase: "Nothing else.",
      category: "🥂"
    },
    {
      id: 88,
      phrase: "Not recently.",
      category: "🔥"
    },
    {
      id: 89,
      phrase: "Not yet.",
      category: "💯"
    },
    {
      id: 90,
      phrase: "Of course.",
      category: "😅"
    },
    {
      id: 91,
      phrase: "Okay.",
      category: "🥧"
    },
    {
      id: 92,
      phrase: "Please fill out this form.",
      category: "😰"
    },
    {
      id: 93,
      phrase: "Please take me to this address.",
      category: "😩"
    },
    {
      id: 94,
      phrase: "Please write it down.",
      category: "😂"
    },
    {
      id: 95,
      phrase: "Really?",
      category: "😤"
    },
    {
      id: 96,
      phrase: "Right here.",
      category: "🥂"
    },
    {
      id: 97,
      phrase: "Right there.",
      category: "🔥"
    },
    {
      id: 98,
      phrase: "See you later.",
      category: "💯"
    },
    {
      id: 99,
      phrase: "See you tomorrow.",
      category: "😅"
    },
    {
      id: 100,
      phrase: "See you tonight.",
      category: "🥧"
    },
    {
      id: 101,
      phrase: "She's pretty.",
      category: "😰"
    },
    {
      id: 102,
      phrase: "Sorry to bother you.",
      category: "😩"
    },
    {
      id: 103,
      phrase: "Stop!",
      category: "😂"
    },
    {
      id: 104,
      phrase: "Take a chance.",
      category: "😤"
    },
    {
      id: 105,
      phrase: "Take it outside.",
      category: "🥂"
    },
    {
      id: 106,
      phrase: "Tell me.",
      category: "🔥"
    },
    {
      id: 107,
      phrase: "Thanks for everything.",
      category: "💯"
    },
    {
      id: 108,
      phrase: "Thanks for your help.",
      category: "😅"
    },
    {
      id: 109,
      phrase: "Thank you.",
      category: "🥧"
    },
    {
      id: 110,
      phrase: "Thank you miss.",
      category: "😰"
    },
    {
      id: 111,
      phrase: "Thank you sir.",
      category: "😩"
    },
    {
      id: 112,
      phrase: "Thank you very much.",
      category: "😂"
    },
    {
      id: 113,
      phrase: "That looks great.",
      category: "😤"
    },
    {
      id: 114,
      phrase: "That's alright.",
      category: "🥂"
    },
    {
      id: 115,
      phrase: "That's enough.",
      category: "🔥"
    },
    {
      id: 116,
      phrase: "That's fine.",
      category: "💯"
    },
    {
      id: 117,
      phrase: "That's it.",
      category: "😅"
    },
    {
      id: 118,
      phrase: "That smells bad.",
      category: "🥧"
    },
    {
      id: 119,
      phrase: "That's not fair.",
      category: "😰"
    },
    {
      id: 120,
      phrase: "That's not right.",
      category: "😩"
    },
    {
      id: 121,
      phrase: "That's right.",
      category: "😂"
    },
    {
      id: 122,
      phrase: "That's too bad.",
      category: "😤"
    },
    {
      id: 123,
      phrase: "That's too many.",
      category: "🥂"
    },
    {
      id: 124,
      phrase: "That's too much.",
      category: "🔥"
    },
    {
      id: 125,
      phrase: "The book is under the table.",
      category: "💯"
    },
    {
      id: 126,
      phrase: "They'll be right back.",
      category: "😅"
    },
    {
      id: 127,
      phrase: "They're the same.",
      category: "🥧"
    },
    {
      id: 128,
      phrase: "They're very busy.",
      category: "😰"
    },
    {
      id: 129,
      phrase: "This doesn't work.",
      category: "😩"
    },
    {
      id: 130,
      phrase: "This is very difficult.",
      category: "😂"
    },
    {
      id: 131,
      phrase: "This is very important.",
      category: "😤"
    },
    {
      id: 132,
      phrase: "Try it.",
      category: "🥂"
    },
    {
      id: 133,
      phrase: "Very good, thanks.",
      category: "🔥"
    },
    {
      id: 134,
      phrase: "We like it very much.",
      category: "💯"
    },
    {
      id: 135,
      phrase: "Would you take a message please?",
      category: "😅"
    },
    {
      id: 136,
      phrase: "Yes, really.",
      category: "🥧"
    },
    {
      id: 137,
      phrase: "You're beautiful.",
      category: "😰"
    },
    {
      id: 138,
      phrase: "You're very nice.",
      category: "😩"
    },
    {
      id: 139,
      phrase: "You're very smart.",
      category: "😂"
    },
    {
      id: 140,
      phrase: "Your things are all here.",
      category: "😤"
    },
    {
      id: 141,
      phrase: "English Slow Normal",
      category: "🥂"
    },
    {
      id: 142,
      phrase: "Be careful.",
      category: "🔥"
    },
    {
      id: 143,
      phrase: "Be careful driving.",
      category: "💯"
    },
    {
      id: 144,
      phrase: "Can you translate this for me?",
      category: "😅"
    },
    {
      id: 145,
      phrase: "Chicago is very different from Boston.",
      category: "🥧"
    },
    {
      id: 146,
      phrase: "Don't worry.",
      category: "😰"
    },
    {
      id: 147,
      phrase: "Everyone knows it.",
      category: "😩"
    },
    {
      id: 148,
      phrase: "Everything is ready.",
      category: "😂"
    },
    {
      id: 149,
      phrase: "Excellent.",
      category: "😤"
    },
    {
      id: 150,
      phrase: "From time to time.",
      category: "🥂"
    },
    {
      id: 151,
      phrase: "Good idea.",
      category: "🔥"
    },
    {
      id: 152,
      phrase: "He likes it very much.",
      category: "💯"
    },
    {
      id: 153,
      phrase: "Help!",
      category: "😅"
    },
    {
      id: 154,
      phrase: "He's coming soon.",
      category: "🥧"
    },
    {
      id: 155,
      phrase: "He's right.",
      category: "😰"
    },
    {
      id: 156,
      phrase: "He's very annoying.",
      category: "😩"
    },
    {
      id: 157,
      phrase: "He's very famous.",
      category: "😂"
    },
    {
      id: 158,
      phrase: "How are you?",
      category: "😤"
    },
    {
      id: 159,
      phrase: "How's work going?",
      category: "🥂"
    },
    {
      id: 160,
      phrase: "Hurry!",
      category: "🔥"
    },
    {
      id: 161,
      phrase: "I ate already.",
      category: "💯"
    },
    {
      id: 162,
      phrase: "I can't hear you.",
      category: "😅"
    },
    {
      id: 163,
      phrase: "I'd like to go for a walk.",
      category: "🥧"
    },
    {
      id: 164,
      phrase: "I don't know how to use it.",
      category: "😰"
    },
    {
      id: 165,
      phrase: "I don't like him.",
      category: "😩"
    },
    {
      id: 166,
      phrase: "I don't like it.",
      category: "😂"
    },
    {
      id: 167,
      phrase: "I don't speak very well.",
      category: "😤"
    },
    {
      id: 168,
      phrase: "I don't understand.",
      category: "🥂"
    },
    {
      id: 169,
      phrase: "I don't want it.",
      category: "🔥"
    },
    {
      id: 170,
      phrase: "I don't want that.",
      category: "💯"
    },
    {
      id: 171,
      phrase: "I don't want to bother you.",
      category: "😅"
    },
    {
      id: 172,
      phrase: "I feel good.",
      category: "🥧"
    },
    {
      id: 173,
      phrase: "If you need my help, please let me know.",
      category: "😰"
    },
    {
      id: 174,
      phrase: "I get off of work at 6.",
      category: "😩"
    },
    {
      id: 175,
      phrase: "I have a headache.",
      category: "😂"
    },
    {
      id: 176,
      phrase: "I hope you and your wife have a nice trip.",
      category: "😤"
    },
    {
      id: 177,
      phrase: "I know.",
      category: "🥂"
    },
    {
      id: 178,
      phrase: "I like her.",
      category: "🔥"
    },
    {
      id: 179,
      phrase: "I'll call you when I leave.",
      category: "💯"
    },
    {
      id: 180,
      phrase: "I'll come back later.",
      category: "😅"
    },
    {
      id: 181,
      phrase: "I'll pay.",
      category: "🥧"
    },
    {
      id: 182,
      phrase: "I'll take it.",
      category: "😰"
    },
    {
      id: 183,
      phrase: "I'll take you to the bus stop.",
      category: "😩"
    },
    {
      id: 184,
      phrase: "I lost my watch.",
      category: "😂"
    },
    {
      id: 185,
      phrase: "I love you.",
      category: "😤"
    },
    {
      id: 186,
      phrase: "I'm an American.",
      category: "🥂"
    },
    {
      id: 187,
      phrase: "I'm cleaning my room.",
      category: "🔥"
    },
    {
      id: 188,
      phrase: "I'm cold.",
      category: "💯"
    },
    {
      id: 189,
      phrase: "I'm coming to pick you up.",
      category: "😅"
    },
    {
      id: 190,
      phrase: "I'm going to leave.",
      category: "🥧"
    },
    {
      id: 191,
      phrase: "I'm good, and you?",
      category: "😰"
    },
    {
      id: 192,
      phrase: "I'm happy.",
      category: "😩"
    },
    {
      id: 193,
      phrase: "I'm hungry.",
      category: "😂"
    },
    {
      id: 194,
      phrase: "I'm married.",
      category: "😤"
    },
    {
      id: 195,
      phrase: "I'm not busy.",
      category: "🥂"
    },
    {
      id: 196,
      phrase: "I'm not married.",
      category: "🔥"
    },
    {
      id: 197,
      phrase: "I'm not ready yet.",
      category: "💯"
    },
    {
      id: 198,
      phrase: "I'm not sure.",
      category: "😅"
    },
    {
      id: 199,
      phrase: "I'm sorry, we're sold out.",
      category: "🥧"
    },
    {
      id: 200,
      phrase: "I'm thirsty.",
      category: "😰"
    },
    {
      id: 201,
      phrase: "I'm very busy. I don't have time now.",
      category: "😩"
    },
    {
      id: 202,
      phrase: "I need to change clothes.",
      category: "😂"
    },
    {
      id: 203,
      phrase: "I need to go home.",
      category: "😤"
    },
    {
      id: 204,
      phrase: "I only want a snack.",
      category: "🥂"
    },
    {
      id: 205,
      phrase: "Is Mr. Smith an American?",
      category: "🔥"
    },
    {
      id: 206,
      phrase: "Is that enough?",
      category: "💯"
    },
    {
      id: 207,
      phrase: "I think it's very good.",
      category: "😅"
    },
    {
      id: 208,
      phrase: "I think it tastes good.",
      category: "🥧"
    },
    {
      id: 209,
      phrase: "I thought the clothes were cheaper.",
      category: "😰"
    },
    {
      id: 210,
      phrase: "It's longer than 2 miles.",
      category: "😩"
    },
    {
      id: 211,
      phrase: "I've been here for two days.",
      category: "😂"
    },
    {
      id: 212,
      phrase: "I've heard Texas is a beautiful place.",
      category: "😤"
    },
    {
      id: 213,
      phrase: "I've never seen that before.",
      category: "🥂"
    },
    {
      id: 214,
      phrase: "I was about to leave the restaurant when my friends arrived.",
      category: "🔥"
    },
    {
      id: 215,
      phrase: "Just a little.",
      category: "💯"
    },
    {
      id: 216,
      phrase: "Just a moment.",
      category: "😅"
    },
    {
      id: 217,
      phrase: "Let me check.",
      category: "🥧"
    },
    {
      id: 218,
      phrase: "Let me think about it.",
      category: "😰"
    },
    {
      id: 219,
      phrase: "Let's go have a look.",
      category: "😩"
    },
    {
      id: 220,
      phrase: "Let's practice English.",
      category: "😂"
    },
    {
      id: 221,
      phrase: "May I speak to Mrs. Smith please?",
      category: "😤"
    },
    {
      id: 222,
      phrase: "More than that.",
      category: "🥂"
    },
    {
      id: 223,
      phrase: "Never mind.",
      category: "🔥"
    },
    {
      id: 224,
      phrase: "Next time.",
      category: "💯"
    },
    {
      id: 225,
      phrase: "No.",
      category: "😅"
    },
    {
      id: 226,
      phrase: "Nonsense.",
      category: "🥧"
    },
    {
      id: 227,
      phrase: "No, thank you.",
      category: "😰"
    },
    {
      id: 228,
      phrase: "Nothing else.",
      category: "😩"
    },
    {
      id: 229,
      phrase: "Not recently.",
      category: "😂"
    },
    {
      id: 230,
      phrase: "Not yet.",
      category: "😤"
    },
    {
      id: 231,
      phrase: "Of course.",
      category: "🥂"
    },
    {
      id: 232,
      phrase: "Okay.",
      category: "🔥"
    },
    {
      id: 233,
      phrase: "Please fill out this form.",
      category: "💯"
    },
    {
      id: 234,
      phrase: "Please take me to this address.",
      category: "😅"
    },
    {
      id: 235,
      phrase: "Please write it down.",
      category: "🥧"
    },
    {
      id: 236,
      phrase: "Really?",
      category: "😰"
    },
    {
      id: 237,
      phrase: "Right here.",
      category: "😩"
    },
    {
      id: 238,
      phrase: "Right there.",
      category: "😂"
    },
    {
      id: 239,
      phrase: "See you later.",
      category: "😤"
    },
    {
      id: 240,
      phrase: "See you tomorrow.",
      category: "🥂"
    },
    {
      id: 241,
      phrase: "See you tonight.",
      category: "🔥"
    },
    {
      id: 242,
      phrase: "She's pretty.",
      category: "💯"
    },
    {
      id: 243,
      phrase: "Sorry to bother you.",
      category: "😅"
    },
    {
      id: 244,
      phrase: "Stop!",
      category: "🥧"
    },
    {
      id: 245,
      phrase: "Take a chance.",
      category: "😰"
    },
    {
      id: 246,
      phrase: "Take it outside.",
      category: "😩"
    },
    {
      id: 247,
      phrase: "Tell me.",
      category: "😂"
    },
    {
      id: 248,
      phrase: "Thanks for everything.",
      category: "😤"
    },
    {
      id: 249,
      phrase: "Thanks for your help.",
      category: "🥂"
    },
    {
      id: 250,
      phrase: "Thank you.",
      category: "🔥"
    },
    {
      id: 251,
      phrase: "Thank you miss.",
      category: "💯"
    },
    {
      id: 252,
      phrase: "Thank you sir.",
      category: "😅"
    },
    {
      id: 253,
      phrase: "Thank you very much.",
      category: "🥧"
    },
    {
      id: 254,
      phrase: "That looks great.",
      category: "😰"
    },
    {
      id: 255,
      phrase: "That's alright.",
      category: "😩"
    },
    {
      id: 256,
      phrase: "That's enough.",
      category: "😂"
    },
    {
      id: 257,
      phrase: "That's fine.",
      category: "😤"
    },
    {
      id: 258,
      phrase: "That's it.",
      category: "🥂"
    },
    {
      id: 259,
      phrase: "That smells bad.",
      category: "🔥"
    },
    {
      id: 260,
      phrase: "That's not fair.",
      category: "💯"
    },
    {
      id: 261,
      phrase: "That's not right.",
      category: "😅"
    },
    {
      id: 262,
      phrase: "That's right.",
      category: "🥧"
    },
    {
      id: 263,
      phrase: "That's too bad.",
      category: "😰"
    },
    {
      id: 264,
      phrase: "That's too many.",
      category: "😩"
    },
    {
      id: 265,
      phrase: "That's too much.",
      category: "😂"
    },
    {
      id: 266,
      phrase: "The book is under the table.",
      category: "😤"
    },
    {
      id: 267,
      phrase: "They'll be right back.",
      category: "🥂"
    },
    {
      id: 268,
      phrase: "They're the same.",
      category: "🔥"
    },
    {
      id: 269,
      phrase: "They're very busy.",
      category: "💯"
    },
    {
      id: 270,
      phrase: "This doesn't work.",
      category: "😅"
    },
    {
      id: 271,
      phrase: "This is very difficult.",
      category: "🥧"
    },
    {
      id: 272,
      phrase: "This is very important.",
      category: "😰"
    },
    {
      id: 273,
      phrase: "Try it.",
      category: "😩"
    },
    {
      id: 274,
      phrase: "Very good, thanks.",
      category: "😂"
    },
    {
      id: 275,
      phrase: "We like it very much.",
      category: "😤"
    },
    {
      id: 276,
      phrase: "Would you take a message please?",
      category: "🥂"
    },
    {
      id: 277,
      phrase: "Yes, really.",
      category: "🔥"
    },
    {
      id: 278,
      phrase: "You're beautiful.",
      category: "💯"
    },
    {
      id: 279,
      phrase: "You're very nice.",
      category: "😅"
    },
    {
      id: 280,
      phrase: "You're very smart.",
      category: "🥧"
    },
    {
      id: 281,
      phrase: "Your things are all here.",
      category: "😰"
    },
    {
      id: 282,
      phrase: "Be careful.",
      category: "😩"
    },
    {
      id: 283,
      phrase: "Be careful driving.",
      category: "😂"
    },
    {
      id: 284,
      phrase: "Can you translate this for me?",
      category: "😤"
    },
    {
      id: 285,
      phrase: "Chicago is very different from Boston.",
      category: "🥂"
    },
    {
      id: 286,
      phrase: "Don't worry.",
      category: "🔥"
    },
    {
      id: 287,
      phrase: "Everyone knows it.",
      category: "💯"
    },
    {
      id: 288,
      phrase: "Everything is ready.",
      category: "😅"
    },
    {
      id: 289,
      phrase: "Excellent.",
      category: "🥧"
    },
    {
      id: 290,
      phrase: "From time to time.",
      category: "😰"
    },
    {
      id: 291,
      phrase: "Good idea.",
      category: "😩"
    },
    {
      id: 292,
      phrase: "He likes it very much.",
      category: "😂"
    },
    {
      id: 293,
      phrase: "Help!",
      category: "😤"
    },
    {
      id: 294,
      phrase: "He's coming soon.",
      category: "🥂"
    },
    {
      id: 295,
      phrase: "He's right.",
      category: "🔥"
    },
    {
      id: 296,
      phrase: "He's very annoying.",
      category: "💯"
    },
    {
      id: 297,
      phrase: "He's very famous.",
      category: "😅"
    },
    {
      id: 298,
      phrase: "How are you?",
      category: "🥧"
    },
    {
      id: 299,
      phrase: "How's work going?",
      category: "😰"
    },
    {
      id: 300,
      phrase: "Hurry!",
      category: "😩"
    },
    {
      id: 301,
      phrase: "I ate already.",
      category: "😂"
    },
    {
      id: 302,
      phrase: "I can't hear you.",
      category: "😤"
    },
    {
      id: 303,
      phrase: "I'd like to go for a walk.",
      category: "🥂"
    },
    {
      id: 304,
      phrase: "I don't know how to use it.",
      category: "🔥"
    },
    {
      id: 305,
      phrase: "I don't like him.",
      category: "💯"
    },
    {
      id: 306,
      phrase: "I don't like it.",
      category: "😅"
    },
    {
      id: 307,
      phrase: "I don't speak very well.",
      category: "🥧"
    },
    {
      id: 308,
      phrase: "I don't understand.",
      category: "😰"
    },
    {
      id: 309,
      phrase: "I don't want it.",
      category: "😩"
    },
    {
      id: 310,
      phrase: "I don't want that.",
      category: "😂"
    },
    {
      id: 311,
      phrase: "I don't want to bother you.",
      category: "😤"
    },
    {
      id: 312,
      phrase: "I feel good.",
      category: "🥂"
    },
    {
      id: 313,
      phrase: "If you need my help, please let me know.",
      category: "🔥"
    },
    {
      id: 314,
      phrase: "I get off of work at 6.",
      category: "💯"
    },
    {
      id: 315,
      phrase: "I have a headache.",
      category: "😅"
    },
    {
      id: 316,
      phrase: "I hope you and your wife have a nice trip.",
      category: "🥧"
    },
    {
      id: 317,
      phrase: "I know.",
      category: "😰"
    },
    {
      id: 318,
      phrase: "I like her.",
      category: "😩"
    },
    {
      id: 319,
      phrase: "I'll call you when I leave.",
      category: "😂"
    },
    {
      id: 320,
      phrase: "I'll come back later.",
      category: "😤"
    },
    {
      id: 321,
      phrase: "I'll pay.",
      category: "🥂"
    },
    {
      id: 322,
      phrase: "I'll take it.",
      category: "🔥"
    },
    {
      id: 323,
      phrase: "I'll take you to the bus stop.",
      category: "💯"
    },
    {
      id: 324,
      phrase: "I lost my watch.",
      category: "😅"
    },
    {
      id: 325,
      phrase: "I love you.",
      category: "🥧"
    },
    {
      id: 326,
      phrase: "I'm an American.",
      category: "😰"
    },
    {
      id: 327,
      phrase: "I'm cleaning my room.",
      category: "😩"
    },
    {
      id: 328,
      phrase: "I'm cold.",
      category: "😂"
    },
    {
      id: 329,
      phrase: "I'm coming to pick you up.",
      category: "😤"
    },
    {
      id: 330,
      phrase: "I'm going to leave.",
      category: "🥂"
    },
    {
      id: 331,
      phrase: "I'm good, and you?",
      category: "🔥"
    },
    {
      id: 332,
      phrase: "I'm happy.",
      category: "💯"
    },
    {
      id: 333,
      phrase: "I'm hungry.",
      category: "😅"
    },
    {
      id: 334,
      phrase: "I'm married.",
      category: "🥧"
    },
    {
      id: 335,
      phrase: "I'm not busy.",
      category: "😰"
    },
    {
      id: 336,
      phrase: "I'm not married.",
      category: "😩"
    },
    {
      id: 337,
      phrase: "I'm not ready yet.",
      category: "😂"
    },
    {
      id: 338,
      phrase: "I'm not sure.",
      category: "😤"
    },
    {
      id: 339,
      phrase: "I'm sorry, we're sold out.",
      category: "🥂"
    },
    {
      id: 340,
      phrase: "I'm thirsty.",
      category: "🔥"
    },
    {
      id: 341,
      phrase: "I'm very busy. I don't have time now.",
      category: "💯"
    },
    {
      id: 342,
      phrase: "I need to change clothes.",
      category: "😅"
    },
    {
      id: 343,
      phrase: "I need to go home.",
      category: "🥧"
    },
    {
      id: 344,
      phrase: "I only want a snack.",
      category: "😰"
    },
    {
      id: 345,
      phrase: "Is Mr. Smith an American?",
      category: "😩"
    },
    {
      id: 346,
      phrase: "Is that enough?",
      category: "😂"
    },
    {
      id: 347,
      phrase: "I think it's very good.",
      category: "😤"
    },
    {
      id: 348,
      phrase: "I think it tastes good.",
      category: "🥂"
    },
    {
      id: 349,
      phrase: "I thought the clothes were cheaper.",
      category: "🔥"
    },
    {
      id: 350,
      phrase: "It's longer than 2 miles.",
      category: "💯"
    },
    {
      id: 351,
      phrase: "I've been here for two days.",
      category: "😅"
    },
    {
      id: 352,
      phrase: "I've heard Texas is a beautiful place.",
      category: "🥧"
    },
    {
      id: 353,
      phrase: "I've never seen that before.",
      category: "😰"
    },
    {
      id: 354,
      phrase: "I was about to leave the restaurant when my friends arrived.",
      category: "😩"
    },
    {
      id: 355,
      phrase: "Just a little.",
      category: "😂"
    },
    {
      id: 356,
      phrase: "Just a moment.",
      category: "😤"
    },
    {
      id: 357,
      phrase: "Let me check.",
      category: "🥂"
    },
    {
      id: 358,
      phrase: "Let me think about it.",
      category: "🔥"
    },
    {
      id: 359,
      phrase: "Let's go have a look.",
      category: "💯"
    },
    {
      id: 360,
      phrase: "Let's practice English.",
      category: "😅"
    },
    {
      id: 361,
      phrase: "May I speak to Mrs. Smith please?",
      category: "🥧"
    },
    {
      id: 362,
      phrase: "More than that.",
      category: "😰"
    },
    {
      id: 363,
      phrase: "Never mind.",
      category: "😩"
    },
    {
      id: 364,
      phrase: "Next time.",
      category: "😂"
    },
    {
      id: 365,
      phrase: "No.",
      category: "😤"
    },
    {
      id: 366,
      phrase: "Nonsense.",
      category: "🥂"
    },
    {
      id: 367,
      phrase: "No, thank you.",
      category: "🔥"
    },
    {
      id: 368,
      phrase: "Nothing else.",
      category: "💯"
    },
    {
      id: 369,
      phrase: "Not recently.",
      category: "😅"
    },
    {
      id: 370,
      phrase: "Not yet.",
      category: "🥧"
    },
    {
      id: 371,
      phrase: "Of course.",
      category: "😰"
    },
    {
      id: 372,
      phrase: "Okay.",
      category: "😩"
    },
    {
      id: 373,
      phrase: "Please fill out this form.",
      category: "😂"
    },
    {
      id: 374,
      phrase: "Please take me to this address.",
      category: "😤"
    },
    {
      id: 375,
      phrase: "Please write it down.",
      category: "🥂"
    },
    {
      id: 376,
      phrase: "Really?",
      category: "🔥"
    },
    {
      id: 377,
      phrase: "Right here.",
      category: "💯"
    },
    {
      id: 378,
      phrase: "Right there.",
      category: "😅"
    },
    {
      id: 379,
      phrase: "See you later.",
      category: "🥧"
    },
    {
      id: 380,
      phrase: "See you tomorrow.",
      category: "😰"
    },
    {
      id: 381,
      phrase: "See you tonight.",
      category: "😩"
    },
    {
      id: 382,
      phrase: "She's pretty.",
      category: "😂"
    },
    {
      id: 383,
      phrase: "Sorry to bother you.",
      category: "😤"
    },
    {
      id: 384,
      phrase: "Stop!",
      category: "🥂"
    },
    {
      id: 385,
      phrase: "Take a chance.",
      category: "🔥"
    },
    {
      id: 386,
      phrase: "Take it outside.",
      category: "💯"
    },
    {
      id: 387,
      phrase: "Tell me.",
      category: "😅"
    },
    {
      id: 388,
      phrase: "Thanks for everything.",
      category: "🥧"
    },
    {
      id: 389,
      phrase: "Thanks for your help.",
      category: "😰"
    },
    {
      id: 390,
      phrase: "Thank you.",
      category: "😩"
    },
    {
      id: 391,
      phrase: "Thank you miss.",
      category: "😂"
    },
    {
      id: 392,
      phrase: "Thank you sir.",
      category: "😤"
    },
    {
      id: 393,
      phrase: "Thank you very much.",
      category: "🥂"
    },
    {
      id: 394,
      phrase: "That looks great.",
      category: "🔥"
    },
    {
      id: 395,
      phrase: "That's alright.",
      category: "💯"
    },
    {
      id: 396,
      phrase: "That's enough.",
      category: "😅"
    },
    {
      id: 397,
      phrase: "That's fine.",
      category: "🥧"
    },
    {
      id: 398,
      phrase: "That's it.",
      category: "😰"
    },
    {
      id: 399,
      phrase: "That smells bad.",
      category: "😩"
    },
    {
      id: 400,
      phrase: "That's not fair.",
      category: "😂"
    },
    {
      id: 401,
      phrase: "That's not right.",
      category: "😤"
    },
    {
      id: 402,
      phrase: "That's right.",
      category: "🥂"
    },
    {
      id: 403,
      phrase: "That's too bad.",
      category: "🔥"
    },
    {
      id: 404,
      phrase: "That's too many.",
      category: "💯"
    },
    {
      id: 405,
      phrase: "That's too much.",
      category: "😅"
    },
    {
      id: 406,
      phrase: "The book is under the table.",
      category: "🥧"
    },
    {
      id: 407,
      phrase: "They'll be right back.",
      category: "😰"
    },
    {
      id: 408,
      phrase: "They're the same.",
      category: "😩"
    },
    {
      id: 409,
      phrase: "They're very busy.",
      category: "😂"
    },
    {
      id: 410,
      phrase: "This doesn't work.",
      category: "😤"
    },
    {
      id: 411,
      phrase: "This is very difficult.",
      category: "🥂"
    },
    {
      id: 412,
      phrase: "This is very important.",
      category: "🔥"
    },
    {
      id: 413,
      phrase: "Try it.",
      category: "💯"
    },
    {
      id: 414,
      phrase: "Very good, thanks.",
      category: "😅"
    },
    {
      id: 415,
      phrase: "We like it very much.",
      category: "🥧"
    },
    {
      id: 416,
      phrase: "Would you take a message please?",
      category: "😰"
    },
    {
      id: 417,
      phrase: "Yes, really.",
      category: "😩"
    },
    {
      id: 418,
      phrase: "You're beautiful.",
      category: "😂"
    },
    {
      id: 419,
      phrase: "You're very nice.",
      category: "😤"
    },
    {
      id: 420,
      phrase: "You're very smart.",
      category: "🥂"
    },
    {
      id: 421,
      phrase: "Your things are all here.",
      category: "🔥"
    },
    {
      id: 422,
      phrase: "English Slow Normal",
      category: "💯"
    },
    {
      id: 423,
      phrase: "Be careful.",
      category: "😅"
    },
    {
      id: 424,
      phrase: "Be careful driving.",
      category: "🥧"
    },
    {
      id: 425,
      phrase: "Can you translate this for me?",
      category: "😰"
    },
    {
      id: 426,
      phrase: "Chicago is very different from Boston.",
      category: "😩"
    },
    {
      id: 427,
      phrase: "Don't worry.",
      category: "😂"
    },
    {
      id: 428,
      phrase: "Everyone knows it.",
      category: "😤"
    },
    {
      id: 429,
      phrase: "Everything is ready.",
      category: "🥂"
    },
    {
      id: 430,
      phrase: "Excellent.",
      category: "🔥"
    },
    {
      id: 431,
      phrase: "From time to time.",
      category: "💯"
    },
    {
      id: 432,
      phrase: "Good idea.",
      category: "😅"
    },
    {
      id: 433,
      phrase: "He likes it very much.",
      category: "🥧"
    },
    {
      id: 434,
      phrase: "Help!",
      category: "😰"
    },
    {
      id: 435,
      phrase: "He's coming soon.",
      category: "😩"
    },
    {
      id: 436,
      phrase: "He's right.",
      category: "😂"
    },
    {
      id: 437,
      phrase: "He's very annoying.",
      category: "😤"
    },
    {
      id: 438,
      phrase: "He's very famous.",
      category: "🥂"
    },
    {
      id: 439,
      phrase: "How are you?",
      category: "🔥"
    },
    {
      id: 440,
      phrase: "How's work going?",
      category: "💯"
    },
    {
      id: 441,
      phrase: "Hurry!",
      category: "😅"
    },
    {
      id: 442,
      phrase: "I ate already.",
      category: "🥧"
    },
    {
      id: 443,
      phrase: "I can't hear you.",
      category: "😰"
    },
    {
      id: 444,
      phrase: "I'd like to go for a walk.",
      category: "😩"
    },
    {
      id: 445,
      phrase: "I don't know how to use it.",
      category: "😂"
    },
    {
      id: 446,
      phrase: "I don't like him.",
      category: "😤"
    },
    {
      id: 447,
      phrase: "I don't like it.",
      category: "🥂"
    },
    {
      id: 448,
      phrase: "I don't speak very well.",
      category: "🔥"
    },
    {
      id: 449,
      phrase: "I don't understand.",
      category: "💯"
    },
    {
      id: 450,
      phrase: "I don't want it.",
      category: "😅"
    },
    {
      id: 451,
      phrase: "I don't want that.",
      category: "🥧"
    },
    {
      id: 452,
      phrase: "I don't want to bother you.",
      category: "😰"
    },
    {
      id: 453,
      phrase: "I feel good.",
      category: "😩"
    },
    {
      id: 454,
      phrase: "If you need my help, please let me know.",
      category: "😂"
    },
    {
      id: 455,
      phrase: "I get off of work at 6.",
      category: "😤"
    },
    {
      id: 456,
      phrase: "I have a headache.",
      category: "🥂"
    },
    {
      id: 457,
      phrase: "I hope you and your wife have a nice trip.",
      category: "🔥"
    },
    {
      id: 458,
      phrase: "I know.",
      category: "💯"
    },
    {
      id: 459,
      phrase: "I like her.",
      category: "😅"
    },
    {
      id: 460,
      phrase: "I'll call you when I leave.",
      category: "🥧"
    },
    {
      id: 461,
      phrase: "I'll come back later.",
      category: "😰"
    },
    {
      id: 462,
      phrase: "I'll pay.",
      category: "😩"
    },
    {
      id: 463,
      phrase: "I'll take it.",
      category: "😂"
    },
    {
      id: 464,
      phrase: "I'll take you to the bus stop.",
      category: "😤"
    },
    {
      id: 465,
      phrase: "I lost my watch.",
      category: "🥂"
    },
    {
      id: 466,
      phrase: "I love you.",
      category: "🔥"
    },
    {
      id: 467,
      phrase: "I'm an American.",
      category: "💯"
    },
    {
      id: 468,
      phrase: "I'm cleaning my room.",
      category: "😅"
    },
    {
      id: 469,
      phrase: "I'm cold.",
      category: "🥧"
    },
    {
      id: 470,
      phrase: "I'm coming to pick you up.",
      category: "😰"
    },
    {
      id: 471,
      phrase: "I'm going to leave.",
      category: "😩"
    },
    {
      id: 472,
      phrase: "I'm good, and you?",
      category: "😂"
    },
    {
      id: 473,
      phrase: "I'm happy.",
      category: "😤"
    },
    {
      id: 474,
      phrase: "I'm hungry.",
      category: "🥂"
    },
    {
      id: 475,
      phrase: "I'm married.",
      category: "🔥"
    },
    {
      id: 476,
      phrase: "I'm not busy.",
      category: "💯"
    },
    {
      id: 477,
      phrase: "I'm not married.",
      category: "😅"
    },
    {
      id: 478,
      phrase: "I'm not ready yet.",
      category: "🥧"
    },
    {
      id: 479,
      phrase: "I'm not sure.",
      category: "😰"
    },
    {
      id: 480,
      phrase: "I'm sorry, we're sold out.",
      category: "😩"
    },
    {
      id: 481,
      phrase: "I'm thirsty.",
      category: "😂"
    },
    {
      id: 482,
      phrase: "I'm very busy. I don't have time now.",
      category: "😤"
    },
    {
      id: 483,
      phrase: "I need to change clothes.",
      category: "🥂"
    },
    {
      id: 484,
      phrase: "I need to go home.",
      category: "🔥"
    },
    {
      id: 485,
      phrase: "I only want a snack.",
      category: "💯"
    },
    {
      id: 486,
      phrase: "Is Mr. Smith an American?",
      category: "😅"
    },
    {
      id: 487,
      phrase: "Is that enough?",
      category: "🥧"
    },
    {
      id: 488,
      phrase: "I think it's very good.",
      category: "😰"
    },
    {
      id: 489,
      phrase: "I think it tastes good.",
      category: "😩"
    },
    {
      id: 490,
      phrase: "I thought the clothes were cheaper.",
      category: "😂"
    },
    {
      id: 491,
      phrase: "It's longer than 2 miles.",
      category: "😤"
    },
    {
      id: 492,
      phrase: "I've been here for two days.",
      category: "🥂"
    },
    {
      id: 493,
      phrase: "I've heard Texas is a beautiful place.",
      category: "🔥"
    },
    {
      id: 494,
      phrase: "I've never seen that before.",
      category: "💯"
    },
    {
      id: 495,
      phrase: "I was about to leave the restaurant when my friends arrived.",
      category: "😅"
    },
    {
      id: 496,
      phrase: "Just a little.",
      category: "🥧"
    },
    {
      id: 497,
      phrase: "Just a moment.",
      category: "😰"
    },
    {
      id: 498,
      phrase: "Let me check.",
      category: "😩"
    },
    {
      id: 499,
      phrase: "Let me think about it.",
      category: "😂"
    },
    {
      id: 500,
      phrase: "Let's go have a look.",
      category: "😤"
    },
    {
      id: 501,
      phrase: "Let's practice English.",
      category: "🥂"
    },
    {
      id: 502,
      phrase: "May I speak to Mrs. Smith please?",
      category: "🔥"
    },
    {
      id: 503,
      phrase: "More than that.",
      category: "💯"
    },
    {
      id: 504,
      phrase: "Never mind.",
      category: "😅"
    },
    {
      id: 505,
      phrase: "Next time.",
      category: "🥧"
    },
    {
      id: 506,
      phrase: "No.",
      category: "😰"
    },
    {
      id: 507,
      phrase: "Nonsense.",
      category: "😩"
    },
    {
      id: 508,
      phrase: "No, thank you.",
      category: "😂"
    },
    {
      id: 509,
      phrase: "Nothing else.",
      category: "😤"
    },
    {
      id: 510,
      phrase: "Not recently.",
      category: "🥂"
    },
    {
      id: 511,
      phrase: "Not yet.",
      category: "🔥"
    },
    {
      id: 512,
      phrase: "Of course.",
      category: "💯"
    },
    {
      id: 513,
      phrase: "Okay.",
      category: "😅"
    },
    {
      id: 514,
      phrase: "Please fill out this form.",
      category: "🥧"
    },
    {
      id: 515,
      phrase: "Please take me to this address.",
      category: "😰"
    },
    {
      id: 516,
      phrase: "Please write it down.",
      category: "😩"
    },
    {
      id: 517,
      phrase: "Really?",
      category: "😂"
    },
    {
      id: 518,
      phrase: "Right here.",
      category: "😤"
    },
    {
      id: 519,
      phrase: "Right there.",
      category: "🥂"
    },
    {
      id: 520,
      phrase: "See you later.",
      category: "🔥"
    },
    {
      id: 521,
      phrase: "See you tomorrow.",
      category: "💯"
    },
    {
      id: 522,
      phrase: "See you tonight.",
      category: "😅"
    },
    {
      id: 523,
      phrase: "She's pretty.",
      category: "🥧"
    },
    {
      id: 524,
      phrase: "Sorry to bother you.",
      category: "😰"
    },
    {
      id: 525,
      phrase: "Stop!",
      category: "😩"
    },
    {
      id: 526,
      phrase: "Take a chance.",
      category: "😂"
    },
    {
      id: 527,
      phrase: "Take it outside.",
      category: "😤"
    },
    {
      id: 528,
      phrase: "Tell me.",
      category: "🥂"
    },
    {
      id: 529,
      phrase: "Thanks for everything.",
      category: "🔥"
    },
    {
      id: 530,
      phrase: "Thanks for your help.",
      category: "💯"
    },
    {
      id: 531,
      phrase: "Thank you.",
      category: "😅"
    },
    {
      id: 532,
      phrase: "Thank you miss.",
      category: "🥧"
    },
    {
      id: 533,
      phrase: "Thank you sir.",
      category: "😰"
    },
    {
      id: 534,
      phrase: "Thank you very much.",
      category: "😩"
    },
    {
      id: 535,
      phrase: "That looks great.",
      category: "😂"
    },
    {
      id: 536,
      phrase: "That's alright.",
      category: "😤"
    },
    {
      id: 537,
      phrase: "That's enough.",
      category: "🥂"
    },
    {
      id: 538,
      phrase: "That's fine.",
      category: "🔥"
    },
    {
      id: 539,
      phrase: "That's it.",
      category: "💯"
    },
    {
      id: 540,
      phrase: "That smells bad.",
      category: "😅"
    },
    {
      id: 541,
      phrase: "That's not fair.",
      category: "🥧"
    },
    {
      id: 542,
      phrase: "That's not right.",
      category: "😰"
    },
    {
      id: 543,
      phrase: "That's right.",
      category: "😩"
    },
    {
      id: 544,
      phrase: "That's too bad.",
      category: "😂"
    },
    {
      id: 545,
      phrase: "That's too many.",
      category: "😤"
    },
    {
      id: 546,
      phrase: "That's too much.",
      category: "🥂"
    },
    {
      id: 547,
      phrase: "The book is under the table.",
      category: "🔥"
    },
    {
      id: 548,
      phrase: "They'll be right back.",
      category: "💯"
    },
    {
      id: 549,
      phrase: "They're the same.",
      category: "😅"
    },
    {
      id: 550,
      phrase: "They're very busy.",
      category: "🥧"
    },
    {
      id: 551,
      phrase: "This doesn't work.",
      category: "😰"
    },
    {
      id: 552,
      phrase: "This is very difficult.",
      category: "😩"
    },
    {
      id: 553,
      phrase: "This is very important.",
      category: "😂"
    },
    {
      id: 554,
      phrase: "Try it.",
      category: "😤"
    },
    {
      id: 555,
      phrase: "Very good, thanks.",
      category: "🥂"
    },
    {
      id: 556,
      phrase: "We like it very much.",
      category: "🔥"
    },
    {
      id: 557,
      phrase: "Would you take a message please?",
      category: "💯"
    },
    {
      id: 558,
      phrase: "Yes, really.",
      category: "😅"
    },
    {
      id: 559,
      phrase: "You're beautiful.",
      category: "🥧"
    },
    {
      id: 560,
      phrase: "You're very nice.",
      category: "😰"
    },
  ];
  
  export default PhrasesData;